<template>
  <q-card flat>
    <q-card-section class="q-pa-sm">
      <div class="row q-gutter-sm q-mb-sm">
        <select_hospInfo
          v-if="ifHospID <= 0"
          :dense="true"
          outlined
          class="col-2"
          label="选择机构"
          v-model="HospID"
          v-model:valueCode="HospID"
        />
        <q-input
          dense
          outlined
          type="date"
          v-model="SettleDate"
          label="对账日期"
          stack-label
        />

        <q-btn
          color="primary"
          icon="search"
          @click="onLoadData"
          :loading="YBPayLoading"
          style="height: 38px;"
        >
          查询
        </q-btn>
        <q-space />
        <!-- <q-btn color="primary" icon="download" style="height: 38px;" @click="onDownload" :loading="HsmUnionpayListLoading">
              下载
            </q-btn>
            <q-btn color="primary" style="height: 38px" icon="fas fa-file-excel" :loading="HsmUnionpayListLoading" @click="onExportData">导出</q-btn> -->
        <!-- <q-btn color="primary" style="height: 38px" icon="note_add" :loading="HsmUnionpayListLoading" @click="onAdd">新增</q-btn> -->
      </div>
      <vxe-grid
        v-bind="gridOptions"
        :footer-method="footerMethod"
        show-footer
        :sort-config="{ multiple: true }"
        :custom-config="{ storage: true }"
        id="custom-config"
        :export-config="gridOptions.tableExport"
        :height="$store.getters.maxPageHeight - 140"
        :loading="YBPayLoading"
        highlight-current-row
        size="mini"
      >
        <template #toolbar_buttons id="custom-config"> </template>
      </vxe-grid>
      <vxe-pager
        perfect
        size="mini"
        :page-sizes="tablePage.pageSizes"
        v-model:current-page="tablePage.currentPage"
        v-model:page-size="tablePage.pageSize"
        :total="tablePage.totalResult"
        :layouts="[
          'PrevPage',
          'JumpNumber',
          'NextPage',
          'FullJump',
          'Sizes',
          'Total',
        ]"
        @page-change="handlePageChange"
      >
      </vxe-pager>
    </q-card-section>
  </q-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import XEUtils from "xe-utils";
import dlg from "@/config/dialog";
import * as ybPay from "@/api/pdm/ybPay.js";
import select_hospInfo from "@/views/pdm/comm/hospInfo_select.vue";

export default {
  components: {
    select_hospInfo,
  },
  computed: {
    ...mapState("", []),
  },
  data() {
    return {
      FilterString: "",
      YBPayLoading: false,
      SettleDate: XEUtils.toDateString(new Date(), "yyyy-MM-dd"),
      HospID: null,
      ifHospID: null,
      tablePage: {
        currentPage: 1,
        pageSize: 20,
        totalResult: 0,
        pageSizes: [15, 20, 50, 100, 5000],
      },
      gridOptions: {
        border: true,
        resizable: true,
        showOverflow: true,
        highlightHoverRow: true,
        align: "left",
        toolbarConfig: {
          perfect: true,
          print: true,
          zoom: true,
          custom: true,
          export: true,
          slots: {
            // 自定义工具栏模板
            buttons: "toolbar_buttons",
          },
        },
        //点击编辑必须配置
        // editConfig: {
        //   trigger: "click",
        //   mode: "cell",
        //   activeMethod: () => {
        //     if (this.lockData) return false;
        //     return true;
        //   },
        // },
        tableExport: {},
        columns: [
          { type: "seq", width: 50, align: "center" },
          //  {
          //   field: "UNIONPAY_ID",
          //   title: "主键",
          //   sortable: true,
          //   align: "left",
          //   width: 160,
          // },
          // {
          //   field: "HospName",
          //   title: "机构名称",
          //   sortable: true,
          //   align: "left",
          //   width: 160,
          // },
          {
            field: "ybOrderNo",
            title: "医保订单号",
            sortable: true,
            align: "left",
            width: 160,
          },
          {
            field: "outTradeNo",
            title: "外部订单号",
            sortable: true,
            align: "left",
            width: 160,
          },
          {
            field: "ybRefundNo",
            title: "医保退款单编号",
            sortable: true,
            align: "left",
            width: 160,
          },
          {
            field: "orderAmt",
            title: "订单金额",
            sortable: true,
            align: "right",
            width: 100,
          },

          {
            field: "tradeTypeName",
            title: "交易类型",
            sortable: true,
            align: "left",
            width: 100,
          },
          {
            field: "finishTime",
            title: "交易完成时间",
            sortable: true,
            align: "center",
            width: 140,
          },

          // {
          //   title: "操作",
          //   width: 200,
          //   slots: { default: "operate" },
          //   align: "center",
          //   fixed: "right",
          // },
          //   {
          //     field: "CatName",
          //     title: "类别",
          //     sortable: true,
          //     editRender: { name: "input" },//点击编辑必须配置
          //     slots: { edit: "CatName" },//点击编辑必须配置
          //     align: "left",
          //     width: 140,
          //   },
        ],
        // data: this.HsmUnionpayList,
        data: null,
      },
    };
  },
  mounted() {
    this.HospID = this.$store.getters["appUser/hospID"];
    this.ifHospID = this.$store.getters["appUser/hospID"];
    this.onLoadData();
  },
  methods: {
    ...mapActions("", []),
    onLoadData() {
      this.YBPayLoading = true;
      if (!this.SettleDate) {
        dlg.alert("对账时间不能为空");
        return;
      }
      ybPay
        .Get_YBReconciliation({
          HospID: this.HospID,
          CUser: this.$store.getters["appUser/userID"],
          SettleDate: this.SettleDate,
          PageIndex: this.tablePage.currentPage,
          pageSize: this.tablePage.pageSize,
        })
        .then((res) => {
          if (res.Code == 0) {
            if (res.Count > 0) {
              this.gridOptions.data = res.Data;
              this.tablePage.totalResult = res.Count;
            }else{
              this.gridOptions.data=null;
            }
          }else{
            dlg.alert(res.Message);
          }
        })
        .finally(() => {
          this.YBPayLoading = false;
          //this.HsmUnionpayListLoading = false;
        });
    },

    //onExportData:{},
    //需要合计：表头必须添加 :footer-method="footerMethod" 跟 show-footer
    footerMethod({ columns, data }) {
      var list = ["orderAmt"]; //需要汇总的字段
      const footerData = [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return "合计";
          }
          if (list.includes(column.property)) {
            return XEUtils.sum(data, column.property);
          }
          return null;
        }),
      ];
      return footerData;
    },
    handlePageChange({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage;
      this.tablePage.pageSize = pageSize;
      this.onLoadData();
    },
  },
};
</script>
