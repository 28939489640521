import { axios } from '@/utils/request'

const api = {
    _service: "/YBPay"
}

export default api


//-------------------医保个人账户在线支付--个账对账单-------------------
export function Get_YBReconciliation(parameter) {
    return axios({
        url: api._service + "/Get_YBReconciliation",
        method: 'post',
        data: parameter
    })
}
